import React from "react";
import styled from "styled-components";
import {
  IoSearch,
  IoCalculator,
  IoTime,
  IoHappy,
  IoAnalytics,
  IoSettings,
  IoFlash,
  IoList,
  IoCheckmark,
  IoCalendar,
  IoPin,
  IoCamera,
  IoPeople,
  IoPerson,
  IoShieldCheckmark,
  IoGlobe,
} from "react-icons/io5";
import { Link } from "gatsby";
import { useModal } from "../components/useModal";

import Image from "../components/image";
import {
  Feature,
  CustomerQuote,
  Modal,
  ImageGraphic,
  CallToAction,
} from "../components/site";
import {
  Button,
  Container,
  Wrapper,
  Row,
  Box,
  FlexButtons,
} from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";

const CustomerName = styled.h4`
  color: #fff;
  position: absolute;
  bottom: 60px;
  left: 60px;
`;

const Manufacturing = (props) => {
  const [itemModalOpen, setItemModalOpen, toggleModal] = useModal();

  const industry_benefits = [
    "Track time accurately with the photo time clock",
    "Seamlessly manage paid and unpaid breaks",
    "Approve timesheets before paying employees",
    "Compliant leave calculations for variable hour employees",
    "Integrate with Xero and IRD for streamlined business processing",
  ];

  return (
    <Layout>
      <Seo
        title="Payroll Software for Manufacturing Businesses"
        description="Focus on scaling your business. Let payroll take care of itself. Stress-free rosters, attendance, timesheets & payroll for Kiwi manufacturing businesses. Get started today."
        pathname={props.location.pathname}
      />
      <Container>
        <Wrapper stackGap={70} noPaddingBottom>
          <Row stackGap={80} alignCenter>
            <Box size={50} stackGap={50} centerOnMobile>
              <Box stackGap={20}>
                <h1 className="-lg -primary badge">
                  Payroll for Manufacturing
                </h1>
                <h2 className="h1">
                  Focus on scaling your business.{" "}
                  <span className="-fontPrimary">
                    Let payroll take care of itself.
                  </span>
                </h2>
                <Box stackGap={15}>
                  {industry_benefits.map((item, i) => {
                    return (
                      <Row
                        alignCenter
                        noBreak
                        stackGap={10}
                        justify="flex-start"
                        key={i}
                        css={{ fontSize: "1.2rem", textAlign: "left" }}
                        centerOnMobile
                      >
                        <IoCheckmark
                          css={{
                            fontSize: "1.8rem",
                            color: "#5eb22e",
                            flex: "0 0 auto",
                          }}
                        />
                        <span>{item}</span>
                      </Row>
                    );
                  })}
                </Box>
              </Box>
              <Box stackGap={15}>
                <FlexButtons>
                  <Button className="primary -lg gtm-cta" to="/signup">
                    Try 14 Days Free
                  </Button>
                  <Button
                    className="grey -lg gtm-cta"
                    onClick={toggleModal}
                    atag
                  >
                    Book a Sales Call
                  </Button>
                </FlexButtons>
                <p css={{ color: "#999" }}>
                  Get started in minutes, no credit card required.
                </p>
              </Box>
              <Image
                filename="FlexiTimeManufacturingCustomers_Sm.png"
                alt="PayHero Manufacturing Payroll Customers"
                centerImage
              />
            </Box>
            <Box size={50} css={{ padding: "40px" }}>
              <ImageGraphic variant="1" />
              <Image
                filename="ManufacturingHero.jpg"
                alt="Payroll for Manufacturing"
                centerImage
                addShadow
                rounded
              />
              <CustomerName>
                <b className="-handWritten">Brazen Clothing,</b>
                <br /> <span css={{ fontSize: "1rem" }}>PayHero Customer</span>
              </CustomerName>
            </Box>
          </Row>
          <hr />
        </Wrapper>
      </Container>
      <Container>
        <Wrapper stackGap={150}>
          <Row stackGap={100} alignCenter mobileReverse>
            <Box size={50}>
              <ImageGraphic variant="2" small />
              <Image
                alt="Clock In & Out | Photo Time Clock | PayHero"
                filename="PayHeroShift_Home.png"
                maxWidth={550}
                addShadow
                centerImage
                rounded
              />
            </Box>
            <Box size={50} stackGap={40} centerOnMobile>
              <Box stackGap={10}>
                <h2 className="h4 -fontDark -fontNormal">
                  Effective employee scheduling
                </h2>
                <h4 className="h2">Roster the right staff at the right time</h4>
              </Box>
              <Feature
                icon={<IoCamera key={0} />}
                title="Start shifts with a selfie"
                description="Get your staff to clock in and out and record breaks with our photo time clock app. It's easy and fun for employees to use, increases your payroll accuracy and saves hours of admin time."
              />
              <Feature
                icon={<IoTime key={0} />}
                title="Automatic timesheets"
                description={[
                  <span>Clock in and outs automatically update </span>,
                  <Link to={process.env.GATSBY_SITE_URL + '/time'}>timesheets</Link>,
                  <span>
                    {" "}
                    in your payroll. No more collecting paper timesheets,
                    checking the hours have been added up correctly and typing
                    them into your{" "}
                  </span>,
                  <Link to={process.env.GATSBY_SITE_URL + '/payroll'}>payroll system</Link>,
                  <span>.</span>,
                ]}
              />
              <Feature
                icon={<IoCalendar key={0} />}
                title="Sync with Droppah rostering"
                description={[
                  "Take your ",
                  <Link to="/rostering">rostering</Link>,
                  " to the next level with Droppah. Use AI auto-scheduling or create a roster that takes skills, availability, shift preferences, wage costs, and other requirements into account. Achieve automated and highly optimised rosters in no time.",
                ]}
              />
            </Box>
          </Row>
          <CustomerQuote
            quote="The photo time clock creates a sense of fairness by accurately recording hours, so both the staff and the owner know they’re not being taken advantage of."
            name="Paul Newson"
            company="Brazen Clothing"
            pic="Brazen_Circle.png"
            large
            center
          />
          <Row stackGap={100} alignCenter mobileReverse>
            <Box size={50} stackGap={40} centerOnMobile>
              <Box stackGap={10}>
                <h2 className="h4 -fontDark -fontNormal">
                  Stress-free payroll
                </h2>
                <h4 className="h2">
                  Payroll software built for businesses like yours
                </h4>
              </Box>
              <Feature
                icon={<IoPeople key={0} />}
                title="Easily manage multiple teams"
                description="Manage time, leave and payroll for different locations or teams with PayHero Teams. Assign staff members to one or more teams and give managers access to manage leave approvals and timesheets for their relevant people. Track your wage costs with Xero tracking category integration."
              />
              <Feature
                icon={<IoPerson key={0} />}
                title="Employee self-service"
                description="Save time and hassle by letting your staff view leave balances and request leave from the employee app. They can access their old payslips too, so you don’t need to dig them out."
              />
              <Feature
                icon={<IoCalculator key={0} />}
                title="Calculate public holidays automatically"
                description="No more painstaking public holiday calculations or costly errors! Save on paperwork and manual processing by using timesheet history to automatically calculate holiday entitlements for variable hour employees."
              />
            </Box>
            <Box size={50}>
              <ImageGraphic variant="2" />
              <Image
                alt="Sync timesheets from mobile directly to payroll"
                filename="PayHero_Review_Manufacturing.png"
                addShadow
                centerImage
                rounded
              />
            </Box>
          </Row>
          <CustomerQuote
            quote="PayHero has been amazing for my manufacturing client – the time clock has helped us save money by accurately recording the hours employees actually work. It's really simple to use and looks great."
            name="Haylee Wrenn"
            company="Accountabill"
            pic="PartnerCircle_Haylee.png"
            center
            large
          />
          <Row stackGap={100} alignCenter>
            <Box size={50}>
              <Image
                filename="PayrollForManufacturing.jpg"
                alt="Payroll software compliance for Manufacturing"
                centerImage
                rounded
              />
            </Box>
            <Box size={50} stackGap={40} centerOnMobile>
              <Box stackGap={10}>
                <h2 className="h4 -fontDark -fontNormal">
                  Simplify compliance
                </h2>
                <h4 className="h2">
                  Focus on your staff and let us take care of the tricky stuff
                </h4>
              </Box>
              <Feature
                icon={<IoFlash key={0} />}
                title="Automated payday filing"
                description={[
                  <Link to="/payday-filing">Payday filing</Link>,
                  <span>
                    {" "}
                    is a piece of cake in PayHero – just connect your account to
                    myIR and we’ll automatically send your payroll information
                    through after every pay.
                  </span>,
                ]}
              />
              <Feature
                icon={<IoShieldCheckmark key={0} />}
                title="Stay onside with the Holidays Act"
                description="Manufacturing is a hot bed of potentially tricky payroll scenarios with part time and variable hour staff and regularly changing work patterns. PayHero follows the latest MBIE Holidays Act guidelines to ensure your employees maintain the correct holiday entitlements, even when things change."
              />
              <Feature
                icon={<IoGlobe key={0} />}
                title="Integrate with Xero"
                description="Automatically send payroll data through to Xero. Keep it simple or assign wage costs to different account codes and tracking categories for a more detailed breakdown."
              />
            </Box>
          </Row>
          <CustomerQuote
            quote="Recently an accounting firm was pitching me for business. They told me they could do my payroll in just two hours a week. I told them it only takes me 10 minutes."
            name="Rochelle Harrison"
            company="Wellington Chocolate Factory"
            pic="Rochelle_Circle.png"
            large
            center
          />
        </Wrapper>
      </Container>
      <Container bg="lightGrey">
        <Wrapper>
          <h2 className="-textCenter">
            All the tools you need to run your manufacturing business better
          </h2>
          <Box stackGap={40}>
            <Row stackGap={40} className="-textCenter">
              <Box size={33}>
                <h3>
                  <IoPin />
                  <br />
                  Easily manage multiple locations
                </h3>
                <p>
                  Manage time, leave and payroll for different locations. Assign
                  staff members to one or more sites and give local managers
                  access to manage employee leave and timesheets at their site.
                  Track your wage costs with Xero tracking category integration.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoAnalytics />
                  <br />
                  Insight into finances and staffing
                </h3>
                <p>
                  Track your wage costs across departments, ensuring you never
                  go over budget. Compare staff costs with your revenue to
                  ensure you have the right number of staff rostered on at busy
                  and quiet periods.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoSettings />
                  <br />
                  Easy set up
                </h3>
                <p>
                  Grab an iPad or Android tablet, login to the photo time clock
                  app and you’re ready to go. No huge setup costs or annual
                  maintenance fees like with fingerprint scanners or card based
                  systems.
                </p>
              </Box>
            </Row>
            <Row stackGap={40} className="-textCenter">
              <Box size={33}>
                <h3>
                  <IoList />
                  <br />
                  Works the way your business does
                </h3>
                <p>
                  Easily set up different departments or teams for clocking in
                  and reporting on costs.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoHappy />
                  <br />
                  Free support
                </h3>
                <p>
                  Our friendly Wellington-based support team is available by
                  phone or email to help you out whenever you need it.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoSearch />
                  <br />
                  Try before you buy
                </h3>
                <p>
                  With a fully featured{" "}
                  <Link to="/signup">free 14 day trial</Link>, you can take
                  PayHero for a risk-free test run before committing.
                </p>
              </Box>
            </Row>
          </Box>
        </Wrapper>
      </Container>
      <CallToAction showSalesButton />
      <Modal
        isActive={itemModalOpen}
        embedURL="https://calendly.com/d/ynv-22p-2r5"
        embedTitle="Book a Sales Call | PayHero Sales"
        handleClose={() => setItemModalOpen(false)}
      />
    </Layout>
  );
};

export default Manufacturing;
